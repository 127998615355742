export default [
  {
    title: '普通',
    icons: ['folib-1','folib-2', 'folib-3', 'folib-4','folib-5','folib-6','folib-7','folib-8','folib-9','folib-10','folib-11','folib-12', 'folib-13', 'folib-14','folib-15','folib-16','folib-17', 'folib-18', 'folib-19', 'folib-20', 'folib-21', 'folib-22', 'folib-23', 'folib-24', 'folib-25', 'folib-26', 'folib-27', 'folib-28']
  },
  {
    title: '卡通',
    icons: ['folib-08','folib-07', 'folib-06', 'folib-05','folib-04','folib-03','folib-02','folib-01','folib-111','folib-121','folib-131','folib-141', 'folib-151', 'folib-161','folib-181','folib-191', 'folib-201', 'folib-211', 'folib-221', 'folib-241', 'folib-231', 'folib-251', 'folib-281', 'folib-261', 'folib-29', 'folib-32', 'folib-30']
  }
]
